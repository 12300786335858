<template>
  <div>
    <div>
      <div class="handle-box">
        <el-select v-model="pagination.userType" placeholder="用户类型" class="handle-select mrb10">
          <el-option key="1" label="Boss" :value="0"></el-option>
          <el-option key="2" label="管理员" :value="1"></el-option>
          <el-option key="3" label="普通用户" :value="2"></el-option>
        </el-select>
<!--        <el-select v-model="pagination.userStatus" placeholder="用户状态" class="handle-select mrb10">-->
<!--          <el-option key="1" label="启用" :value="true"></el-option>-->
<!--          <el-option key="2" label="禁用" :value="false"></el-option>-->
<!--        </el-select>-->
        <el-input v-model="pagination.searchKey" placeholder="用户名/手机号" class="handle-input mrb10"></el-input>
        <el-button type="primary" icon="el-icon-search" @click="searchUser()">搜索</el-button>
        <el-button type="danger" @click="clearSearch()">清除参数</el-button>
      </div>
      <el-table :data="users" border class="table" header-cell-class-name="table-header">
        <el-table-column fixed="right" header-align="center" align="center" min-width="150" label="操作">
          <template slot-scope="scope" >
            <!--          <el-button type="text" size="small" v-if="scope.row.allocation === 0" @click="useExpenseUser(scope.row)">分配公司</el-button>-->
            <!--          <el-button type="text" style="color:#1d953f" size="small" v-if="scope.row.allocation === 1" >已分配</el-button>-->
            <el-button type="text" size="small" @click="disEditUserVisible(scope.row,0)">编辑</el-button>
            <el-button type="text" size="small" >删除</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="id" label="ID" width="55" align="center"></el-table-column>
        <el-table-column prop="username" label="用户名" align="center"></el-table-column>
<!--        <el-table-column prop="phoneNumber" label="手机号" align="center"></el-table-column>-->
<!--        <el-table-column prop="email" label="邮箱" align="center"></el-table-column>-->
        <el-table-column label="赞赏" width="100" align="center">
          <template slot-scope="scope">
            <el-input size="medium" maxlength="30" v-model="scope.row.admire"
                      @blur="changeUserAdmire(scope.row)"></el-input>
          </template>
        </el-table-column>
        <el-table-column label="用户状态" align="center">
          <template slot-scope="scope">
            <el-tag :type="scope.row.userStatus === false ? 'danger' : 'success'"
                    disable-transitions>
              {{scope.row.userStatus === false ? '禁用' : '启用'}}
            </el-tag>
            <el-switch @click.native="changeUserStatus(scope.row)" v-model="scope.row.userStatus"></el-switch>
          </template>
        </el-table-column>
        <el-table-column label="头像" align="center">
          <template slot-scope="scope">
            <el-image lazy class="table-td-thumb" :src="scope.row.avatar" fit="cover"></el-image>
          </template>
        </el-table-column>
<!--        <el-table-column label="性别" align="center">-->
<!--          <template slot-scope="scope">-->
<!--            <el-tag type="success"-->
<!--                    v-if="scope.row.gender === 1"-->
<!--                    disable-transitions>-->
<!--              男-->
<!--            </el-tag>-->
<!--            <el-tag type="success"-->
<!--                    v-else-if="scope.row.gender === 2"-->
<!--                    disable-transitions>-->
<!--              女-->
<!--            </el-tag>-->
<!--            <el-tag type="success"-->
<!--                    v-else-->
<!--                    disable-transitions>-->
<!--              保密-->
<!--            </el-tag>-->
<!--          </template>-->
<!--        </el-table-column>-->
        <el-table-column prop="introduction" label="简介" align="center"></el-table-column>
        <el-table-column label="用户类型" width="100" align="center">
          <template slot-scope="scope">
            <el-tag type="success"
                    v-if="scope.row.userType === 0"
                    style="cursor: pointer"
                    @click.native="editUser(scope.row)"
                    disable-transitions>
              Boss
            </el-tag>
            <el-tag type="success"
                    v-else-if="scope.row.userType === 1"
                    style="cursor: pointer"
                    @click.native="editUser(scope.row)"
                    disable-transitions>
              管理员
            </el-tag>
            <el-tag type="success"
                    v-else
                    style="cursor: pointer"
                    @click.native="editUser(scope.row)"
                    disable-transitions>
              普通用户
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="注册时间" align="center"></el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination background layout="total, prev, pager, next"
                       :current-page="pagination.current"
                       :page-size="pagination.size"
                       :total="pagination.total"
                       @current-change="handlePageChange">
        </el-pagination>
      </div>
    </div>

    <!-- 编辑弹出框 -->
    <el-dialog title="修改用户类型"
               :visible.sync="editVisible"
               width="30%"
               :before-close="handleClose"
               :append-to-body="true"
               destroy-on-close
               center>
      <div class="myCenter">
        <el-radio-group v-model="changeUser.userType">
          <el-radio-button :label="0">Boss</el-radio-button>
          <el-radio-button :label="1">管理员</el-radio-button>
          <el-radio-button :label="2">普通用户</el-radio-button>
        </el-radio-group>
      </div>

      <span slot="footer" class="dialog-footer">
          <el-button @click="handleClose()">取 消</el-button>
          <el-button type="primary" @click="saveEdit()">确 定</el-button>
        </span>
    </el-dialog>
    <el-dialog
      :title="'修改'+this.editUserInfo.username+'用户信息'"
      :visible.sync="editUserVisible"
      >
      <el-form ref="editUserInfo" :model="editUserInfo" label-width="80px">
        <el-form-item label="用户名称">
          <el-input v-model="editUserInfo.username" clearable></el-input>
        </el-form-item>

        <el-form-item label="用户邮箱">
          <el-input v-model="editUserInfo.email" clearable></el-input>
        </el-form-item>

        <el-form-item label="用户头像">
          <div style="display: flex">
            <el-image lazy class="table-td-thumb"
                      style="margin-right: 10px;"
                      :preview-src-list="[editUserInfo.avatar]"
                      :src="editUserInfo.avatar"
                      fit="cover"></el-image>

            <el-input class="el-input-img" v-model="editUserInfo.avatar"></el-input>
            <!--            <el-button type="primary" >修改头像</el-button>-->
          </div>
          <el-button style="margin-top: 10px" type="success" @click="changeAvatar" round>修改头像<i class="el-icon-upload el-icon--right"></i></el-button>


        </el-form-item>

        <el-form-item label="性别">
          <el-select v-model="editUserInfo.gender" placeholder="请选择选择性别">
            <el-option label="男" value="1"></el-option>
            <el-option label="女" value="2"></el-option>
            <el-option label="保密" value="0"></el-option>
          </el-select>
        </el-form-item>


        <el-form-item label="是否启用">
          <template>
            <el-tag :type="editUserInfo.userStatus === false ? 'danger' : 'success'"
                    disable-transitions>
              {{editUserInfo.userStatus === false ? '禁用' : '启用'}}
            </el-tag>
            <el-switch @click.native="changeUserStatus(editUserInfo)" v-model="editUserInfo.userStatus"></el-switch>
          </template>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="updateUserInfo(editUserInfo)" >确认修改</el-button>
          <el-button @click="disEditUserVisible(null,1)">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog
    :title="'修改'+this.editUserInfo.username+'用户头像'"
    :visible.sync="disUserImg">
      <el-form ref="editUserInfo" :model="editUserInfo" label-width="80px">
        <el-form-item label="头像" prop="avatar">
<!--          <div style="display: flex">-->
<!--            <el-input v-model="editUserInfo.avatar"></el-input>-->
<!--            <el-image lazy class="table-td-thumb"-->
<!--                      style="margin-left: 10px"-->
<!--                      :preview-src-list="[editUserInfo.avatar]"-->
<!--                      :src="editUserInfo.avatar"-->
<!--                      fit="cover"></el-image>-->
<!--          </div>-->
          <uploadPicture :isAdmin="true" :prefix="'webAvatar'" style="margin-top: 15px" @addPicture="addAvatar"
                         :maxSize="5"
                         :maxNumber="1"></uploadPicture>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
const uploadPicture = () => import( "../common/uploadPicture");
  export default {
    data() {
      return {
        editUserInfo: {},
        // 是否弹出编辑弹出框
        editUserVisible: false,
        pagination: {
          current: 1,
          size: 10,
          total: 0,
          searchKey: "",
          userStatus: null,
          userType: null
        },
        users: [],
        changeUser: {
          id: null,
          userType: null
        },
        editVisible: false,
        disUserImg: false,
      }
    },
    components: {
      uploadPicture
    },

    computed: {},

    watch: {},

    created() {
      this.getUsers();
    },

    mounted() {
    },

    methods: {

      changeAvatar() {
        this.disUserImg = true;
        // this.$message({
        //   message: '请先保存用户信息',
        //   type: 'warning'
        // });
      },
      addAvatar(res) {
        this.editUserInfo.avatar = res;
      },
      updateUserInfo(row){
        if ( row !== null){
          if (row.gender === '男') {
            this.editUserInfo.gender = 1
          }else if (row.gender === '女') {
            this.editUserInfo.gender = 2
          }else {
            this.editUserInfo.gender = 0
          }
        }
        this.$http.post(this.$constant.baseURL + "/admin/user/updateUserInfo", this.editUserInfo, true)
          .then(res => {
            if (res.code === 200) {
              this.$message({
                message: '修改成功',
                type: 'success'
              });
              this.disEditUserVisible(null,1)
              this.getUsers();
            } else {
              this.$message({
                message: res.msg,
                type: 'error'
              });
            }
          })
      },
      /**
       * 编辑用户
       * @param row
       * @param type 0显示编辑页面页面 1关闭编辑页面
       */
      disEditUserVisible(row,type){
        if ( row !== null){
          if (row.gender === 1) {
            row.gender = '男'
          }else if (row.gender === 2) {
            row.gender = '女'
          }else {
            row.gender = '保密'
          }
        }

        if (type === 0){
          this.editUserInfo = row
          this.editUserVisible = true;
        }else {
          this.editUserVisible = false;
        }
      },
      clearSearch() {
        this.pagination = {
          current: 1,
          size: 10,
          total: 0,
          searchKey: "",
          userStatus: null,
          userType: null
        }
        this.getUsers();
      },
      getUsers() {
        this.$http.post(this.$constant.baseURL + "/admin/user/list", this.pagination, true)
          .then((res) => {
            if (!this.$common.isEmpty(res.data)) {
              this.users = res.data.records;
              this.pagination.total = res.data.total;
            }
          })
          .catch((error) => {
            this.$message({
              message: error.message,
              type: "error"
            });
          });
      },
      changeUserStatus(user) {
        this.$http.get(this.$constant.baseURL + "/admin/user/changeUserStatus", {
          userId: user.id,
          flag: user.userStatus
        }, true)
          .then((res) => {
            this.$message({
              message: "修改成功！",
              type: "success"
            });
          })
          .catch((error) => {
            this.$message({
              message: error.message,
              type: "error"
            });
          });
      },
      changeUserAdmire(user) {
        if (!this.$common.isEmpty(user.admire)) {
          this.$confirm('确认保存？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'success',
            center: true
          }).then(() => {
            this.$http.get(this.$constant.baseURL + "/admin/user/changeUserAdmire", {
              userId: user.id,
              admire: user.admire
            }, true)
              .then((res) => {
                this.$message({
                  message: "修改成功！",
                  type: "success"
                });
              })
              .catch((error) => {
                this.$message({
                  message: error.message,
                  type: "error"
                });
              });
          }).catch(() => {
            this.$message({
              type: 'success',
              message: '已取消保存!'
            });
          });
        }
      },
      editUser(user) {
        this.changeUser.id = user.id;
        this.changeUser.userType = user.userType;
        this.editVisible = true;
      },
      handlePageChange(val) {
        this.pagination.current = val;
        this.getUsers();
      },
      searchUser() {
        this.pagination.total = 0;
        this.pagination.current = 1;
        this.getUsers();
      },
      handleClose() {
        this.changeUser = {
          id: null,
          userType: null
        };
        this.editVisible = false;
      },
      saveEdit() {
        this.$http.get(this.$constant.baseURL + "/admin/user/changeUserType", {
          userId: this.changeUser.id,
          userType: this.changeUser.userType
        }, true)
          .then((res) => {
            this.handleClose();
            this.getUsers();
            this.$message({
              message: "修改成功！",
              type: "success"
            });
          })
          .catch((error) => {
            this.$message({
              message: error.message,
              type: "error"
            });
          });
      }
    }
  }
</script>

<style scoped>

  .handle-box {
    margin-bottom: 20px;
  }

  .handle-select {
    width: 120px;
  }

  .handle-input {
    width: 160px;
    display: inline-block;
  }

  .table {
    width: 100%;
    font-size: 14px;
  }

  .mrb10 {
    margin-right: 10px;
    margin-bottom: 10px;
  }

  .table-td-thumb {
    display: block;
    margin: auto;
    width: 40px;
    height: 40px;
  }

  .pagination {
    margin: 20px 0;
    text-align: right;
  }

  .el-switch {
    margin: 5px;
  }
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 40px;
    height: 40px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 40px;
    height: 40px;
    display: block;
  }

  .el-input-img{
    /*比其他输入框宽度少50px*/
    width: calc(100% - 50px);
    height: 40px;
    display: block;
  }
</style>
